<template>
  <Container :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <MsgItem
        v-for="(item, index) in list"
        :key="index"
        :title="item.title"
        :borderBottom="true"
        :path="index.toString()"
        :showIcon="false"
        @goToRoute="goToRoute"
      >
      </MsgItem>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Container from "../components/Container";
import NoData from "@/components/NoData";
import MsgItem from "../components/MsgItem.vue";
export default {
  components: {
    NoData,
    Container,
    MsgItem,
  },
  data() {
    return {
    };
  },
  watch: {
    list: {
      handler: function (newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("help", ["helpData"]),
    list() {
      if (!this.helpData) {
        return [];
      } else {
        return this.helpData.map((data) => {
          return {
            title: data.title,
          };
        });
      }
    },
    checkResponseStatus() {
      return this.list && this.list.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("help", ["getHelp"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    goToRoute(path) {
      this.$router.push({
        path: "/my/helpCenter/helpContent",
        query: { id: path },
      });
    },
  },
  created() {
    this.getHelp({
      code: "kmbzzx",
      page: 1,
      limit: 20,
    });
  },
};
</script>